:root {
  --foreground: #24292f;
  --background: #ffffff;
  --container: #ffffff;
  --divider: #d0d7de;
  --content: #ffffff;
  --gap: 1px;
}

.dark {
  --foreground: #adbac7;
  --background: #22272e;
  --container: #212529;
  --divider: #444c56;
  --content: #212529;
  --gap: 1px;
}

body,
html {
  padding: 0;
  margin: 0;
}

.container {
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;

  background: var(--divider);
  color: var(--foreground);
  gap: var(--gap);
  padding: 0;
  margin: 0;
  display: grid;
  height: calc(100vh - 64px);
  grid-template-columns: 1fr 4fr auto;
  grid-template-rows: 2fr 2fr 3fr 3fr 100px;
  grid-template-areas:
    "left center right"
    "left center right "
    "mid mid mid"
    "bottom bottom bottom"
    "footer footer footer";
}

.bottom {
  grid-area: bottom;
}

.maximize-cell {
  position: absolute;
  inset: 0;
  bottom: 100px;
  top: 64px;
  z-index: 1;
}

.left {
  display: flex;
  flex-direction: column;
  grid-area: left;
}

.center {
  grid-area: center;
  display: flex;
}

.right {
  grid-area: right;
}

.videoStats {
  overflow: scroll;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--content);
  gap: 1em;
  flex-wrap: wrap;
  padding-top: 16px;
}

.mid {
  background: var(--divider);
  gap: 1px;
  grid-area: mid;
}

.footer {
  grid-area: footer;
}

.section {
  display: flex;
}

.timeline-content {
  display: flex;
}

.timeline-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1em;
}

.timeline-labels {
  display: flex;
  justify-content: space-between;
}

.video-block {
  background: #24242b;
}

.sfu-message {
  display: flex;
}

.sfu-message-incoming {
  background: #232b20;
}

.sfu-message-outgoing {
  background: #2b1919;
}

.scroll-pane {
  position: relative;
  height: 100%;
}
.scroll-area {
  position: absolute;
  overflow: scroll;
  inset: 0;
}

.participant {
  opacity: 0.5;
}

.participant.active-participant {
  opacity: 1;
}

.log-message {
  background: var(--background);
}

.log-message-warn {
  background: #342e1b;
}

.log-message-error {
  background: #3d2019;
}

.log-message-info {
  background: #2d3c4c;
}

.log-message-body {
  flex-direction: column;
  display: flex;
  background: var(--divider);
  gap: var(--gap);
}

.events-body {
  flex-direction: column;
  display: flex;
  gap: var(--gap);
  background: var(--background);
}

.row-message {
  display: flex;
  gap: 8px;
  font-weight: lighter;
  padding: 8px 16px;
  overflow-wrap: anywhere;
  word-break: break-all;
}

.timeline-body {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;
}

.future {
  opacity: 0.5;
}

.acton-message-name,
.webrtcIssue-message-name {
  font-weight: bold;
}

.webrtcIssue-message-name.message-icon::before {
  content: "⚠️";
  margin-right: 8px;
}

.webrtcIssue-message-container.message-verbose {
  display: flex;
  gap: 4px;
}

.networkTestResults-message-container,
.networkTestResults-message-container > ul {
  display: flex;
  flex: 1;
}

.networkTestResults-message-container > ul {
  margin: 0 !important; /* here to override the inline margin that JsonTree adds */
}

.action-message-container {
  display: flex;
  gap: 4px;
}

.chart-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.chart-box {
  margin: 5px;
  background: var(--background);
  padding: 5px;
}
